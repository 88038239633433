import { createRouter, createWebHistory } from "vue-router";

const Login = () => import('../views/Login.vue')
const Home = () => import('../views/Home.vue');
const Landing = () => import('../views/Landing.vue');
const Uncapped = () => import('../views/Uncapped.vue');
const Reward = () => import('../views/Reward.vue');
const Register = () => import('../views/Register.vue');
const RegisterDone = () => import('../views/RegisterDone.vue');
const Support = () => import('../views/Support.vue');
const SupportDone = () => import('../views/SupportSubmissionDone.vue');
const InstallationDone = () => import('../views/InstallationRequestDone.vue');
const RequestBasic = () => import('../views/Installation/RequestBasic.vue');
const RequestUps = () => import('../views/Installation/RequestUps.vue');
const RequestDCIBasic = () =>
  import("../views/Installation/RequestDCIBasic.vue");
const NotFound = () => import('../views/404.vue');
const FAQ = () => import('../views/FAQ.vue');
const Vouchers = () => import('../views/Vouchers.vue');
const ChangePassword = () => import('../views/ChangePassword.vue');
const ChangePasswordDone = () => import('../views/ChangePasswordDone.vue');
const PasswordReset = () => import('../views/PasswordReset.vue');
const PasswordResetDone = () => import('../views/PasswordResetDone.vue');
const Referrals = () => import('../views/Referrals.vue');
const LoadVoucher = () => import('../views/LoadVoucher.vue');
const Bundles = () => import('../views/Bundles.vue');
const EFTPayment = () => import('../views/EFTPayment.vue');
const DepositPayment = () => import('../views/DepositPayment.vue');
const DebitPayment = () => import('../views/DebitPayment.vue');
const DebitPaymentDone = () => import('../views/DebitPaymentDone.vue');
const TermsOfService = () => import('../views/TermsOfService.vue');
const Popia = () => import('../views/Popia.vue');
const Hotspot = () => import("../views/Hotspot.vue");
const HotspotAccount = () => import('../views/HotspotAccount.vue');
const UncappedAccount = () => import('../views/UncappedAccount.vue');
const NewPasswordReset = () => import('../views/NewPasswordReset.vue');
const HotspotPackages = () => import('../views/HotspotPackages.vue');
const InstallationTOS = () => import('../views/InstallationTOS.vue');
const MagicLogin = () => import('../views/MagicLogin.vue');
const Products = () => import('../views/Products.vue');
const DebitOrderForm = () => import('../views/DebitOrderForm.vue');
const TicketStatus = () => import('../views/TicketStatus.vue');
const UpdateInstallation = () => import('../views/UpdateInstallation.vue');
const CoverageNotFound = () => import('../views/CoverageNotFound.vue');
const ExistingInstallation = () => import('../views/ExistingInstallation.vue');
const InstallationProcess = () => import('../views/Installation/InstallationProcess.vue');
const DCIInstallationProcess = () =>
  import("../views/Installation/DCIInstallationProcess.vue");
const Payments = () => import('../views/Payments.vue');
const OnboardingProcess = () => import("../views/OnboardingProcess.vue");
const ConfirmationInstall = () => import("../views/ConfirmedInstall.vue");
const AmandlaPromo = () => import("../views/AmandlaPromo.vue");
const Refund = () => import("../views/Refund.vue");
const PrivacyPolicy = () => import("../views/TermsOfService.vue");
const PeachPaymentStatus = () => import ("../views/PeachPaymentStatus.vue");
import Reschedule from "../views/Reschedule.vue"
import axios from "axios";
import { store, onboardingData } from "../store.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/login/:(.*)",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/uncapped/",
    name: "Uncapped",
    component: Uncapped,
  },
  {
    path: "/register/",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/done/",
    name: "RegisterDone",
    component: RegisterDone,
    props: true,
  },
  {
    path: "/password-reset/",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/forgot-password/",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/password-reset/done/",
    name: "PasswordResetDone",
    component: PasswordResetDone,
  },
  {
    path: "/support/",
    name: "Support",
    component: Support,
  },
  {
    path: "/customer-support/",
    name: "Customer Support",
    component: Support,
  },
  {
    path: "/support/submitted/",
    name: "SupportDone",
    component: SupportDone,
  },
  {
    path: "/installation/request/done/:lioUUID",
    name: "InstallationDone",
    component: InstallationDone,
  },
  {
    path: "/installation/request/",
    name: "Installation",
    component: RequestBasic,
  },
  {
    path: "/ups-request",
    name: "Ups",
    component: RequestUps,
  },
  {
    path: "/frequently-asked-questions/",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/referrals/",
    alias: "/referrals/:(.*)",
    name: "Referrals",
    component: Referrals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hotspot/",
    name: "Hotspot",
    component: Hotspot,
    meta: {
      requiresAuth: true,
      tab: "hotspot",
    },
  },
  {
    path: "/landing/",
    name: "Landing",
    component: Landing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/change-password/",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/load-voucher/",
    name: "LoadVoucher",
    component: LoadVoucher,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/change-password/done/",
    name: "ChangePasswordDone",
    component: ChangePasswordDone,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/find-vouchers/",
    name: "Vouchers",
    component: Vouchers,
  },
  {
    path: "/data-bundles/",
    name: "Bundles",
    component: Bundles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rewards/",
    name: "Reward",
    component: Reward,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-bundles/eft-payment-details/",
    name: "EFTPayment",
    component: EFTPayment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-bundles/banking-details/",
    name: "DepositPayment",
    component: DepositPayment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment/card-payment/:paymentType/:product/",
    name: "DebitPayment",
    component: DebitPayment,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-bundles/card-payment/done/:checkoutID/",
    name: "DebitPaymentDone",
    component: DebitPaymentDone,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/popia/",
    name: "Popia",
    component: Popia,
  },
  {
    path: "/terms-of-service/",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/privacy-policy",
    redirect: (to) => {
      window.location.href = "https://toomuchwifi.co.za/privacy-policy/";
    },
  },
  {
    path: "/callmeback/",
    redirect: (to) => {
      window.location.href = "https://portal.toomuchwifi.co.za/support/";
    },
  },

  {
    path: "/magic/:magicStr/",
    name: "MagicLogin",
    component: MagicLogin,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/accounts/hotspot",
    name: "HotspotAccount",
    component: HotspotAccount,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/accounts/uncapped",
    name: "UncappedAccount",
    component: UncappedAccount,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reset-password/:uuid/",
    name: "NewPasswordReset",
    component: NewPasswordReset,
  },
  {
    path: "/installation/terms/:code",
    name: "InstallationTOS",
    component: InstallationTOS,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/products/",
    name: "Products",
    component: Products,
  },
  {
    path: "/request/:(.*)",
    name: "Installation",
    component: RequestBasic,
  },
  {
    path: "/debit/order/",
    name: "DebitOrderForm",
    component: DebitOrderForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ticket/status/:reference?",
    name: "TicketStatus",
    component: TicketStatus,
  },
  {
    path: "/hotspot-packages",
    name: "HotspotPackages",
    component: HotspotPackages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/request/installation/",
    name: "RequestBasic",
    component: RequestBasic,
  },
  {
    path: "/dci-request/installation/",
    name: "RequestDCIBasic",
    component: RequestDCIBasic,
  },
  {
    path: "/new-dci/installation/:installationUUID/",
    name: "DCIInstallationProcess",
    component: DCIInstallationProcess,
  },
  {
    path: "/new/installation/:installationUUID/",
    name: "InstallationProcess",
    component: InstallationProcess,
  },
  {
    path: "/update/installation/:installationUUID/",
    name: "UpdateInstallation",
    component: UpdateInstallation,
  },
  {
    path: "/outside-coverage/",
    name: "CoverageNotFound",
    component: CoverageNotFound,
  },
  {
    path: "/existing/installation/:installationUUID/",
    name: "ExistingInstallation",
    component: ExistingInstallation,
  },
  {
    path: "/payments/hotspot/:product",
    name: "PaymentsHotspot",
    component: Payments,
  },
  {
    path: "/payments/uncapped/:accountId",
    name: "PaymentsAccount",
    component: Payments,
  },

  {
    path: "/payments/lio/:lioUUID",
    name: "PaymentsLio",
    component: Payments,
  },
  {
    path: "/payments/fpa-extra/:fpaExtraId",
    name: "PaymentsFPAExtra",
    component: Payments,
  },
  {
    path: "/payments/ups-purchase/:purchaseUUID",
    name: "PaymentsUpsPurchase",
    component: Payments,
  },

  {
    path: "/payments/hardware/:hardware/",
    name: "PaymentsHardware",
    component: Payments,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/scheduling/confirmation/:uuid",
    name: "ConfirmationInstall",
    component: ConfirmationInstall,
  },
  {
    path: "/reschedule/:ref",
    name: "Reschedule",
    component: Reschedule,
  },
  {
    path: "/installation-information/:uuid",
    component: OnboardingProcess,
    props: true,
  },
  {
    path: "/amandla+",
    name: "AmandlaPromo",
    component: AmandlaPromo,
  },
  {
    path: "/refund/installation/:installation_uuid",
    name: "RefundInstallation",
    component: Refund,
  },
  {
    path: "/refund/fpa/:account_id",
    name: "RefundUncapped",
    component: Refund,
  },
  {
    path: "/privacy-policy/:account_id?",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
 
  },
  {
    path: "/peach-payment-status/:checkout_id",
    name: "PeachPaymentStatus",
    component: PeachPaymentStatus,
 
  }
];

// Add a one mili second delay between switching of views
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 100);
    });
  },
});

const hasAcceptedPrivacyPolicy = async function () {
  let response = await axios.get("privacy-policy-status").then(
    function (response) {
      if (response.status === 200) {
        console.log("this is the response", response)
        store.isTermsOfUseAccepted = response.data
      }
      else{
        store.isTermsOfUseAccepted = false
      }
    }
  )
}

const authenticate = async function () {
  let vm = this;
  let response = await axios
    .get("auth/")
    .then(function (response) {
      if (response.status === 200) {
        store.isUserAuthenticated = true;

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          store.isUserAuthenticated = false;
        }
      }
    });
};



const uncappedBundles = async function () {
  let response = await axios
    .get("uncapped-package/")
    .then(function (response) {
      if (response.status === 200) {
        store.uncappedBundles = response.data.uncapped_packages;
      }
    })
    .catch((error) => {
      console.log(error)
    });
};

const checkIfUserAcceptedPrivacyPolicy = function(){
  if (!store.isTermsOfUseAccepted){
    hasAcceptedPrivacyPolicy().then(
      function(){
          if (!store.isTermsOfUseAccepted){
            console.log("should be pushing router")
            router.push({name:"PrivacyPolicy"})
          }
          else{
            router.push({name:"Home"})
          }
      }
    )
  }
}
router.beforeEach((to, from, next) => {

  if (!store.uncappedBundles){
    uncappedBundles();
  }

  

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.isUserAuthenticated){
      authenticate().then(function () {

        

        if (store.isUserAuthenticated != null && !store.isUserAuthenticated) {
          

          if (to.name != "Landing" && to.name != "Hotspot") {
            router.push({ name: "Landing" });
          }
          else {

            next();
          }
        } else {

          if (to.name == "Landing")
            router.push({ name: "Home" })
          else if (to.name == "Hotspot") {
            router.push({ name: "Home", query: { tab: "hotspot" } });
          } else {
            next();
          }
        }
      })
    }
    else{
      
      next()

    }
   ;
  } else {
    next();
  }
});

export default router;
